import { isEqual } from '@/public/src/pages/checkout_new/utils'

/**
 * @description 处理 abt 数据映射
 * */
export const setAbtInfo = (state, { locals, abtInfo }) => {
  //
  const orderConfirmDiscountSwitch = locals?.SiteUID?.indexOf('br') === -1

  //
  const placeOrderStatusMove = locals?.SiteUID?.indexOf('br') === -1

  // 开启总优惠金额展示
  const isShowSaveAmount = isEqual(
    abtInfo?.Shownewsaveamount?.param?.show_new_save_amount,
    'on'
  )

  // 开启总优惠金额展示
  const isShowActivityPopoverNewLayout = isEqual(
    abtInfo?.BubbleStyle?.param?.BubbleStyle,
    'new'
  )

  const placeOrderButtonPayStatus = isEqual(
    abtInfo?.PlaceOrderButtonPay?.param?.PlaceOrderButtonPayStatus,
    1
  )
  const bnplPlaceOrderStatus = isEqual(
    abtInfo?.bnplPlaceOrder?.param?.bnplPlaceOrderStats,
    '2'
  )
  const placeOrderButtonUpgradeStatus = isEqual(
    abtInfo?.placeOrderButtonUpgrade?.param?.placeOrderButtonUpgradeStatus,
    '2'
  )
  const placeOrderFreeShippingstatus = isEqual(
    abtInfo.placeOrderFreeShipping?.param?.placeOrderFreeShippingstatus,
    '1'
  )
  const PlaceOrderQuickshipWordStatus = isEqual(
    abtInfo.PlaceOrderQuickshipWord?.param?.PlaceOrderQuickshipWordStatus,
    '1'
  )
  const LocationPrimeSaverStatus = isEqual(
    abtInfo.LocationPrimeSaver?.param?.LocationPrimeSaverStatus,
    '1'
  )

  const isUsePopJumpAddressWrite = isEqual(
    abtInfo.JumpAddressStyle?.param?.JumpAddressStyle,
    'Pop'
  )

  const isUseNewCheckoutLoading = isEqual(
    abtInfo?.PaymentSafetyExperiment?.param?.PaymentSafetyFlow,
    'Show'
  )

  const isShowNewTcPpTip = isEqual(
    abtInfo?.Shownewtcpptip?.p,
    'on'
  )

  const isOrderpaylistbottomstatus = isEqual(
    abtInfo?.orderpaylistbottomdefault?.param?.orderpaylistbottomstatus,
    '1'
  )

  const isOrderpaylistbottomnodefaultstatus = isEqual(
    abtInfo?.orderpaylistbottomnodefault?.param?.orderpaylistbottomnodefaultstatus,
    'bottom'
  )

  const paymentSafetyExperimentAbt = abtInfo?.PaymentSafetyExperiment?.param?.PaymentSafetyCheckout
 
  const isUseNewSwitchByAbt = abtInfo?.NewCheckoutRetain?.param?.new_checkout_retain_switch === 'on'

  const retainDialogTransProcStrategy = abtInfo?.TransProcRetenpopStrategy?.param?.TransProcRetenpopStrategy || 'default'

  const footerBtnBenefitLinkage = abtInfo?.BenefitLinkage?.param?.Cart_Benefit_Linkage || 'OFF'

  const addFreeShippingNewEntrance = abtInfo?.CheckoutAddFreeshipping?.param?.new_entrance || 'typeOff'  
  const addFreeShippingInPriceDetail = abtInfo?.CheckoutAddFreeshipping?.param?.price_detail_entrance === 'on'  


  // 更新state
  Object.assign(state.abtState, {
    orderConfirmDiscountSwitch,
    placeOrderStatusMove,
    isShowSaveAmount,
    isShowActivityPopoverNewLayout,
    placeOrderButtonPayStatus,
    bnplPlaceOrderStatus,
    placeOrderButtonUpgradeStatus,
    placeOrderFreeShippingstatus,
    PlaceOrderQuickshipWordStatus,
    LocationPrimeSaverStatus,
    isUsePopJumpAddressWrite,
    isUseNewCheckoutLoading,
    isShowNewTcPpTip,
    isOrderpaylistbottomstatus,
    isOrderpaylistbottomnodefaultstatus,
    paymentSafetyExperimentAbt,
    addFreeShippingNewEntrance,
    addFreeShippingInPriceDetail,
    isUseNewSwitchByAbt,
    retainDialogTransProcStrategy,
    footerBtnBenefitLinkage,
  })
}
