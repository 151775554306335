import { setIsMultiMall, setLayoutMode, setBagsInfo, setQsFreightStatus, changeRealQuickShip } from './shoppingBags'
import { setAddressInfo } from './address'
import { setFooterInfo } from './footer'
import { setAbtInfo } from './abt'
import { setShippingMethod } from './shippingMethod'

/**
 * @description 处理数据映射关系【数据映射，处理来自api的数据，组装成自己想要的数据，放到对应业务的state中】
 * @param {object} 来自服务器的数据
 * */
const handleDataMapping = (state, { locals, cartsInfo, abtInfo, checkout }) => {
  // common mapping
  setGBcssRight(state, locals.GB_cssRight)

  /** 下方业务数据映射处理，新建文件, 形如购物袋 */

  // 数据变更处理
  changeRealQuickShip({ cartsInfo, checkout })

  // 购物袋 mapping
  setIsMultiMall(state, cartsInfo.is_multi_mall)
  setLayoutMode(state, { cartsInfo, abtInfo })
  setBagsInfo(state, { cartsInfo, checkout, abtInfo })
  setQsFreightStatus(state, { checkout })
  
  // 运输方式
  setShippingMethod(state, { checkout, isInit: true })

  // address mapping
  setAddressInfo(state, { checkout })

  // footer mapping
  setFooterInfo(state, { locals, checkout, abtInfo })
  // abt mapping
  setAbtInfo(state, { locals, checkout, abtInfo })

  // coupon mapping
}

/**
 * @description 站点镜像【ar il站点是true。其他是false】
 * @GB_cssRight {boolean} true ar il站点
 * @GB_cssRight {boolean} false ar 其他站点
 * */
const setGBcssRight = (state, payload) => {
  Object.assign(state, {
    GB_cssRight: payload
  })
}

export default handleDataMapping
