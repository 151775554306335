import { watch } from 'vue'
import { useMapState, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { judgeActivityIsExist } from '@/public/src/pages/checkout_new/utils/index.js'
import { getQueryString } from '@shein/common-function'
import { exposeScenesAbtAggregatorBff as exposeScenesAbtAggregator } from 'public/src/pages/checkout/tools/EventAggregator.js'

export default () => {
  const {
    checkout,
    shoppingBagsState,
    abtInfo,
    cacheInfo,
    checkoutParams,
    cartsInfo,
    togetherBuy,
    selectedShippingList,
  } = useMapState([
    'checkout',
    'shoppingBagsState',
    'abtInfo',
    'cacheInfo',
    'checkoutParams',
    'cartsInfo',
    'togetherBuy',
    'shippingMethodState.selectedShippingList',
  ])

  const {
    visibleBuyTogether,
    isShowReturnCoupons,
    fullShippingMethodsSelectedInfo,
    selectedPrimeProduct,
    hasPaymentDiscount,
    hasCardBinDiscount,
    selectedPaymentItemStatus,
    isShopTransit,
    isRiskAddress,
    isHasAddress,
    paymentInfo
  } = useMapGetters([
    'visibleBuyTogether',
    'isShowReturnCoupons',
    'fullShippingMethodsSelectedInfo',
    'selectedPrimeProduct',
    'hasPaymentDiscount',
    'hasCardBinDiscount',
    'selectedPaymentItemStatus',
    'isShopTransit',
    'isRiskAddress',
    'isHasAddress',
    'paymentInfo'
  ])

  // 自动使用优惠券
  const handleAutoUseCoupon = () => {
    exposeScenesAbtAggregator.addEvent({
      id: 'expose_scenesabt:simple',
      data: {
        scenes: 'shipping_coupon_stackable',
        type: checkout.value?.coupon_list?.some(coupon => (
          ![53, 55].includes(+coupon.type_id) && +coupon.applyFor === 9
        )) ? 1 : 0
      }
    })
  }

  // 自动使用积分
  const handleAutoUsePoint = () => {
    exposeScenesAbtAggregator.addEvent({
      id: 'expose_scenesabt:simple',
      data: {
        scenes: 'autopoint', // 自动使用积分的场景才会上报
        max_available_point: checkout.value?.point?.maxAvailableForTrack,
        auto_point_result: checkout.value?.point?.autoPointResult,
        is_below_auto_use_point_threshold: checkout.value?.point?.isBelowAutoUsePointThreshold
      }
    })
  }

  // 自动使用钱包
  const handleAutoUseWallet = () => {
    exposeScenesAbtAggregator.addEvent({
      id: 'expose_scenesabt:simple',
      data: {
        scenes: 'walletBalance',
        type: checkout.value?.wallet_balance?.subtractPrice?.amount > 0 ? 1 : 0,
        auto_wallet_result: checkout.value?.wallet_balance?.amount > 0 ? 1 : 0,
      }
    })
  }

  // 购物袋单商品
  const handleSingleBag = () => {
    const carts = shoppingBagsState.value?.bagsInfo?.all
    const hasOneGood = carts?.length === 1
    const abtFlag = abtInfo.value?.ShoppingBagType?.param?.ShoppingBagType == 'Item_Detail'

    if(abtFlag && hasOneGood) {
      // 只有一件商品，且是qs，在库存位不足数量下调取非qs商品数量，不上报
      const { real_quick_ship } = carts?.[0]
      const qsStockNo = +real_quick_ship === 2
      if(qsStockNo) return

      exposeScenesAbtAggregator.addEvent({
        id: 'expose_scenesabt:simple',
        data: {
          scenes: 'SingleProdShoppingBag',
        }
      })
    }
  }

  const handleQuickShipping = () => {
    if(+checkout.value?.quick_shipping?.current_quick_ship_status === 2) {
      daEventCenter.triggerNotice({
        id: 'expose_quick_shipping:simple',
        data: {
          quickship_tp: '1'
        }
      })
    }
  }

  /**
   * @description: 获取下单页面的权益点，上报埋点
   * @return {*}
   */
  const handleInterestsData = () => {
    watch(
      [() => checkout.value, () => !!visibleBuyTogether.value, () => !!isShowReturnCoupons.value],
      () => {
        const { prime_info, saveCardInfo, actual_shipping_price, quick_shipping  } = checkout.value
        // 随手购
        const buyTogether = !!visibleBuyTogether.value
        // 付费会员
        const primeClub = prime_info && !!Object.keys(prime_info).length && !!prime_info?.prime_products?.length
        // 超省卡
        const saverCard = saveCardInfo && !!saveCardInfo?.products?.length
        // 免邮
        const freeShipping = actual_shipping_price?.amountWithSymbol && actual_shipping_price?.amount == 0
        // 返劵
        const returnCoupon = !!isShowReturnCoupons.value
        // 有qs时效商品
        const quickShippingGoods = !!Number(quick_shipping?.current_quick_ship_status)
        
        const interestsData = {}
        if (buyTogether) { interestsData.Casual = true } // 随手购
        if (primeClub) { interestsData.SHEIN_Club = true } // 随单购
        if (returnCoupon) { interestsData.regain_coupon = true } // 订单返劵
        if (saverCard) { interestsData.saver = true } // 超省卡
        if (freeShipping) { interestsData.freeshiping = true } // 免邮
        if (quickShippingGoods) { interestsData.quickship = true } // 时效商品
        if (hasPaymentDiscount.value) { interestsData.pay_promotion = true } // 支付优惠
        if (hasCardBinDiscount.value) { interestsData.bin_promotion = true } // 卡bin优惠
    
        const isNotChange = (obj1, obj2) => {
          if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
            return false
          }
          const keys1 = Object.keys(obj1)
          const keys2 = Object.keys(obj2)

          if (keys1.length !== keys2.length) {
            return false
          }
          for (const key of keys1) {
            if (!keys2.includes(key)) {
              return false
            }
          }
          return true
        }
        const cacheInterestsData = typeof sessionStorage.getItem('checkoutPageInterestsData') === 'string' ? JSON.parse(sessionStorage.getItem('checkoutPageInterestsData')) : {}

        if (isNotChange(cacheInterestsData, interestsData)) {
          return
        }

        sessionStorage.setItem('checkoutPageInterestsData', JSON.stringify(interestsData))

        daEventCenter.triggerNotice({
          id: 'expose_interests:simple',
          data: {
            interests: Object.keys(interestsData).join('`')
          }
        })
      },
      {
        deep: true, immediate: true
      }
    )
  }

  /**
   * @description: 获取下单页面的内容在可视区域是处于第几屏，用于离开下单页面上报埋点
   * @return {*}
   */
  const handlePageScreenInViewArea = () => {
    const windowHeight = window.innerHeight
    const currentScreen = Math.ceil(document.documentElement.scrollTop / windowHeight) + 1

    daEventCenter.triggerNotice({
      id: 'expose_browse_depth:simple',
      data: {
        page_depth: currentScreen
      }
    })
  }

  // 默认运输方式曝光埋点
  const handleShippingAnalysis = () => {
    const selectedShipping = selectedShippingList.value?.map(item => {
      const default_shippingmethod = item?.transport_type || ''
      let default_from = 0 // 默认运输方式来源；0-后端返回 1-前端缓存
      if (cacheInfo.value?.user_shipping_methods?.used) {
        const cacheTransportTypeList =
          cacheInfo.value.user_shipping_methods.val
        if (
          cacheTransportTypeList.some(
            val =>
              +val.mall_code === +item.mall_code &&
              val.transport_type === default_shippingmethod
          )
        ) {
          default_from = 1
        }
      }
      return { default_shippingmethod, default_from }
    }) || []

    if (selectedShipping.length > 1) {
      return {
        default_from: selectedShipping
          .map(item => item.default_from)
          .join('^'),
        default_shippingmethod: selectedShipping
          .map(item => item.default_shippingmethod)
          .join('^')
      }
    }

    daEventCenter.triggerNotice({
      id: 'expose_default_shippingmethod:simple',
      data: selectedShipping[0]
    })
  }

  // 点击成单接口上报埋点
  const handleCreateOrderAnalysis = (params = {}) => {
    const getOverType = () => {
      const list = shoppingBagsState.value?.bagsInfo?.all?.map(_ => _.aggregateProductBusiness?.singleOrTotalOver) || []
      let isSingle = false
      let isTotal = false

      list.forEach(_ => {
        if(+_ === 1) isSingle = true
        if(+_ === 2) isTotal = true
      })

      if(isSingle && isTotal) {
        return '0'
      } else if(isSingle) {
        return 'single'
      } else if(isTotal) {
        return 'total'
      }

      return '0'
    }

    const getLabelId = () => {
      let label_id = sessionStorage.getItem('filterType') ?? 0

      if(label_id == 'none' || !label_id) label_id = 0

      return label_id
    }

    const getCouponMethod = () => {
      let coupon_method = '-'
      const autoUseByAbt = abtInfo.value?.Placeordercouponoptimization?.param?.place_order_coupon_optimization == 'on' ? true : false
      const auto_coupon = getQueryString({ key: 'auto_coupon' }) || ''
      if (autoUseByAbt) coupon_method = checkout.value?.coupon_list?.map(_ => _.couponCode?.toLowerCase())?.join(',') === auto_coupon?.toLowerCase() ? '1' : '0'
    
      return coupon_method
    }

    const getShippingTimeDesc = () => {
      const { shipping_methods_mall = [] } = checkout.value

      const shipTimeDesList = shipping_methods_mall.map(mall => {
        return {
          mall_code: mall?.mall_code || '',
          shipping_methods: mall?.shipping_methods.map(method => {
            let shipping_time_desc = 'none'
            let isShowPercentDes = +method.desc_type === 1 ? !!method?.delivery_day_percent_desc : !!method.shipping_day_percent_desc
            let isShowPercentIcon = !!method?.shipping_day_percents?.length
            if (isShowPercentDes && isShowPercentIcon) {
              shipping_time_desc = 'desc_and_rule'
            } else if (isShowPercentDes && !isShowPercentIcon) {
              shipping_time_desc = 'desc_only'
            }
  
            return { transport_type: method.transport_type, shipping_time_desc }
          })
        }
      })

      let des = ''
      if (!shipTimeDesList.length) return des
    
      const desList = shipTimeDesList.map(mall => {
        const selectedShipping = fullShippingMethodsSelectedInfo.value?.[mall.mall_code]
    
        return mall.mall_code + '`' + mall.shipping_methods?.[selectedShipping.index]?.shipping_time_desc
      }) || []
    
      des = desList?.join(',')
      return des
    }


    const {
      order_id,
      uorder_id,
      order_id_list,
      result,
      result_reason,
      check_out_scene,
      extraPayAnalysisInfo = {},
    } = params || {}

    // 埋点文档： https://wiki.dotfashion.cn/pages/viewpage.action?pageId=1062029860 
    const getHomogenizationObj = function () {
      const paymentMethods = checkout.value?.payment_info?.payments || []

      const isPaymentHomogenizationShow = paymentMethods?.some(item => item?.payments)
      if (!isPaymentHomogenizationShow) {
        return {
          default_pay_mtd: '-',
          selected_pay_mtd: '-'
        }
      }
    
      const isHadOnlinePayDiscountInfoValue = code => {
        return checkout.value?.onlinePayDiscountInfo?.onlinePayDiscountValue?.[code] || null
      }
    
      const homogenization = paymentMethods?.find(item => item?.payments) || {}
      const defaultSelectPay = paymentMethods?.find(item => item?.last_payment)
      const isDefaultHomogenizationPay = homogenization?.code === defaultSelectPay?.code
      const payment_method = checkoutParams.value?.payment_code || ''
      const onlinePayDiscountInfoValue = isHadOnlinePayDiscountInfoValue(payment_method)
      const homogenizationIsHadOnlinePayDiscountInfoValue = (homogenization?.payments || [])?.find(item => isHadOnlinePayDiscountInfoValue(item))
      const payment_method_is_default = paymentMethods?.some(item => item?.last_payment && item?.code == payment_method)
    
      return {
        default_pay_mtd: `${homogenization?.code || homogenization?.title}\`${isDefaultHomogenizationPay ? 1 : 0}\`${homogenizationIsHadOnlinePayDiscountInfoValue ? 1 : 0}\`${isDefaultHomogenizationPay ? defaultSelectPay?.code : '-'}\`${payment_method_is_default ? 1 : 0}\`${onlinePayDiscountInfoValue ? 1 : 0}\`-`,
        selected_pay_mtd: `${homogenization?.code || homogenization?.title}\`${isDefaultHomogenizationPay ? 1 : 0}\`${homogenizationIsHadOnlinePayDiscountInfoValue ? 1 : 0}\`${payment_method}\`${payment_method_is_default ? 1 : 0}\`${onlinePayDiscountInfoValue ? 1 : 0}\`-`,
      }
    }

    const getSignSupportInfo = () => {
      let is_token = '-'
      const payment_method = checkoutParams.value?.payment_code || ''
      const paymentMethods = checkout.value?.payment_info?.payments || []
      const selectedPayment = paymentMethods?.find(p =>
        p.code === payment_method
      )
      is_token = +selectedPayment?.sign_support === 1 ? payment_method : is_token
      return is_token
    }

    const commonParams = {
      mall_shipping_recommend: Object.values(fullShippingMethodsSelectedInfo.value)?.map?.(_ => `{mall:${_.mall_code},shipping_method:${+_?.index === 0 ? '1' : '0'}}`).join(''),
      mall_freight_insurance: Object.values(fullShippingMethodsSelectedInfo.value)?.map?.(_ => `{mall:${_.mall_code},freight_insurance:${+_?.use_insurance === 1 ? '1' : '0'}}`).join(''),
      coupon_use: +checkout.value.coupon_price?.amount > 0 ? '1' : '0',
      payment_method: checkoutParams.value.payment_code,
      payment_method_id: checkoutParams.value.payment_id,
      payment_code: checkoutParams.value.payment_code,
      address_type: checkout.value.address?.tag ?? '0',
      version: '1', // 1（代表新样式）、2（代表老样式）
      prime_level: selectedPrimeProduct.value?.product_cycle_type || 0,
      prime_deduction: checkout.value.prime_deduce_price?.amountWithSymbol || 0,
      prime_product_id: checkoutParams.value.prime_product_code,
      is_insured_goods: shoppingBagsState.value?.bagsInfo?.all?.find(_ => +_.product.insurable !== 0) ? '1' : '0',
      shipping_marked_price: checkout.value.shipping_methods_mall?.map(_ => _.shipping_methods).flat(Infinity)?.find(_ => +_.freight_activity_type === 1) ? '1' : '0',
      store_info: cartsInfo.value.app_burry_point?.store_info?.map(_ => `${_.store_type}\`${_.store_code}\`${_.goods_quality}\`${_.mall_code}`)?.join(','),
      over_type: getOverType(),
      mall_code: [...new Set(shoppingBagsState.value?.bagsInfo?.all?.map(_ => _.mall_code))]?.join(','),
      quickship_tp: checkout.value?.quick_shipping?.current_quick_ship_status,
      freeshipping_tp: checkout.value?.actual_shipping_price?.amountWithSymbol && (+checkout.value?.actual_shipping_price?.amount === 0) ? '0' : '1',
      final_qs_freight_status: Object.values(fullShippingMethodsSelectedInfo.value)?.[0]?.switch_qs_flag ?? '-',
      Saver: checkoutParams.value.save_card_product_code || '-',
      is_choose_foldedpayment: selectedPaymentItemStatus.value?.is_choose_foldedpayment,
      is_vaulting: selectedPaymentItemStatus.value?.is_vaulting,
      ...(!!togetherBuy.value.recommendProducts?.length ? { transaction_activity: 'add_more_items' } : {}),
      ...(!!checkoutParams.value.coupon_list?.length ? { coupon_info: checkoutParams.value.coupon_list } : {}),
      ...getHomogenizationObj(),
      ...extraPayAnalysisInfo,
    }

    // 只有是宅配地址的时候，需要判断当前地址是否是风控地址
    if (!isShopTransit.value && !!isHasAddress.value) {
      commonParams.address_risk = isRiskAddress.value
    }

    const CLICK_PLACE_ORDER_PARAMS = {
      shipping_time_desc: getShippingTimeDesc(),
      coupon_method: getCouponMethod(),
      label_id: getLabelId(),
      is_payment_default: selectedPaymentItemStatus.value?.is_default,
      product_type: 'commontype',
      check_out_scene,
      ...commonParams,
    }
    daEventCenter.triggerNotice({
      id: 'click_place_order:simple.beacon',
      data: CLICK_PLACE_ORDER_PARAMS
    })

    const CLICK_PLACE_ORDER_RESULT_PARAMS = {
      points: +checkoutParams.value.points > 0 ? '1' : '0',
      result,
      result_reason,
      order_id,
      uorder_id,
      order_id_list,
      activity_method: judgeActivityIsExist(shoppingBagsState.value?.bagsInfo?.all || [], [3, 10]),
      mall_shipping_method_id: Object.values(fullShippingMethodsSelectedInfo.value)?.map?.(_ => `{mall:${_.mall_code},transport_type:${_?.transport_type}}`).join(''),
      token_status: selectedPaymentItemStatus.value?.token_status,
      is_token: getSignSupportInfo(),
      ...commonParams,
    }

    daEventCenter.triggerNotice({
      id: 'click_place_order_result:simple.beacon',
      data: CLICK_PLACE_ORDER_RESULT_PARAMS
    })
  }

  const handleClickContinueResult = (params = {}) => {
    const product_type = checkoutParams.value?.save_card_product_code ? 'order_sheinsaver' : checkoutParams.value?.prime_product_code ? 'order_membership' : 'commontype'
    const data = {
      failure_reason: '', // 失败原因 属性值格式为：{code:xxx,result:xxx,type:xxx}，其中result（哪里出错）和type（什么错误类型）两部分的规则如下： 当卡号错误时，result的值为card_num
      failure_type: '', // 失败类型 front_end/rear_end
      instalment_num: '',
      is_remember: '',
      order_id_list: '', // 每个子订单号之间用 隔开
      order_no: '',
      payment_method: '',
      prime_deduction: checkout.value.prime_deduce_price?.amountWithSymbol || 0, // 付费会员抵扣金额
      product_type, // 产品类型 单独购付费会员发membership； 单独购超省卡发sheinsaver； 其他订单类型发commontype
      scene: '', // 场景类型 新卡上报new，非新卡上报token
      status: 'fail',
      pay_response_time: '',
      token_id: '', // token_id
      uorder_id: '', // 关联订单号 
      is_cvvnolimit: '0', // 是否需要cvv 1 需要 0 不需要
      ...(params || {})
    }

    daEventCenter.triggerNotice({
      id: 'click_continue:simple',
      data: {
        is_need_cvv: data?.is_need_cvv || '-',
        prime_deduction: data?.prime_deduction || ''
      }
    })

    daEventCenter.triggerNotice({
      id: 'click_continue_result:simple',
      data
    })
  }

  const handleNotSupportCodTipsCode = () => {
    const { notSupportCodTips } = checkout.value || {}
    const payments = paymentInfo.value?.payments || []
    const notSupportCodTipsCode = notSupportCodTips?.code || ''
    if (notSupportCodTipsCode !== '' && payments.some(item => item.code == 'cod')) {
      daEventCenter.triggerNotice({
        id: 'expose_notsupportcodcode:simple',
        data: {
          fail_reason: notSupportCodTipsCode
        }
      })
    }
  } 


  return {
    handleAutoUseCoupon,
    handleAutoUsePoint,
    handleAutoUseWallet,
    handleSingleBag,
    handleQuickShipping,
    handleInterestsData,
    handlePageScreenInViewArea,
    handleShippingAnalysis,
    handleCreateOrderAnalysis,
    handleClickContinueResult,
    handleNotSupportCodTipsCode,
  }
}
